jQuery(document).ready(function() {
	jQuery("textarea.js-textarea-spec-char-replace").change(function(e) {
		var specCharFunc = jQuery(e.currentTarget).data('spec-char-replace'); 
		window[specCharFunc](e.currentTarget); 
	});
});
document.addEventListener('DOMContentLoaded', function () {
  document
    .querySelectorAll('.js-selectAllCancelForm')
    .forEach((selectAllButton) => {
      selectAllButton.addEventListener('click', function (e) {
        e.preventDefault();
        selectAll(document.cancelForm.x_cancel_eg_request_id);
      });
    });

  document
    .querySelectorAll('.js-selectNoneCancelForm')
    .forEach((selectNoneButton) => {
      selectNoneButton.addEventListener('click', function (e) {
        e.preventDefault();
        selectNone(document.cancelForm.x_cancel_eg_request_id);
      });
    });

  document.querySelectorAll('#newfriendfamily').forEach((newFriendFamily) => {
    newFriendFamily.addEventListener('click', function (e) {
      const element = document.getElementById('ffformdiv');

      if (element) {
        element.remove();
      }
    });
  });
});
