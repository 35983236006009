/*
  For the purpose of this file, "time" refers to the <time> HTML tag.
  We call this function often throughout the file to minimize the likelihood
  that a user will notice the date change.
 */

// has to be var because it's been declared in other files
var CyberGrants = CyberGrants || {};

CyberGrants.timeConvert = (function (self) {
  const observer = new MutationObserver(callback);
  let targetNode = null;

  const config = {
    childList: true,
    subtree: true,
  };

  function callback(mutationList, observer) {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        observer.disconnect();
        convertDatesAndTimes();
        observe();
      }
    }
  }

  function observe() {
    return observer.observe(targetNode, config);
  }

  function extractDate(str) {
    const match = str.match(/<%=(.*?)%>/);
    return match ? match[1] : null;
  }

  function convertDatesAndTimes() {
    const allTheTags = document.querySelectorAll(
      'time:not(.converted), option.time-convert:not(.converted)',
    );

    if (!allTheTags.length) {
      return;
    }

    const locale = navigator.language;

    const dateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };

    for (let i = 0; i < allTheTags.length; i++) {
      const workingTag = allTheTags[i];
      const isTime = workingTag.tagName === 'TIME';
      // if it's <time>, there will be a single value to convert
      // if it's an <option>, then there could be multiple.  See below.
      const dateTime = isTime && workingTag.getAttribute('datetime');

      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T([01]\d|2[0-3]):([0-5]\d)$/;

      if (isTime && dateRegex.test(dateTime)) {
        const date = new Date(`${dateTime}T00:00:00`);

        workingTag.innerText = new Intl.DateTimeFormat(
          locale,
          dateOptions,
        ).format(date);
        workingTag.classList.add('converted');
      } else if (isTime && dateTimeRegex.test(dateTime)) {
        const date = new Date(dateTime);

        workingTag.innerText = new Intl.DateTimeFormat(locale, {
          ...dateOptions,
          ...timeOptions,
        }).format(date);
        workingTag.classList.add('converted');
      } else if (isTime && timeRegex.test(dateTime)) {
        // if it's just a time, there's nothing to do
        // add the class just to skip it next time
        workingTag.classList.add('converted');
        continue;
      } else if (!isTime) {
        // if it's an option, there might be more than one date to
        // convert within a given workingTag
        const allTheOptionsRegex = /<%=(.*?)%>/g;
        const allTheTags = workingTag.innerText.match(allTheOptionsRegex);

        for (let j = 0; j < allTheTags.length; j++) {
          const innerTag = allTheTags[j];
          const workingDateTime = extractDate(innerTag);
          const date = new Date(workingDateTime);

          workingTag.innerText = workingTag.innerText.replace(
            innerTag,
            new Intl.DateTimeFormat(locale, {
              ...dateOptions,
            }).format(new Date(date)),
          );
        }
        workingTag.classList.add('converted');
      }
    }
  }

  return {
    convertDatesAndTimes,
    observe,
    set targetNode(node) {
      targetNode = node;
    },
  };
})(CyberGrants.timeConvert || {});

CyberGrants.timeConvert.convertDatesAndTimes();

jQuery(document).ready(function () {
  CyberGrants.timeConvert.convertDatesAndTimes();
  CyberGrants.timeConvert.targetNode =
    document.getElementById('cgcontent') || document.body;
  CyberGrants.timeConvert.observe();
});
